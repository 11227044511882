import MainLayout from 'src/layouts/MainLayout';
import { lazy } from 'react';
import { RouteI } from 'src/models/router';
import { RoutePath } from 'src/enums/routePath';
import AdminStoreLayout from 'src/layouts/AdminStoreDashboardLayout';

const LoginPage = import('../pages/Login');
const UserPage = import('../pages/User');
const ReceiptCreate = import('../pages/Store/Receipt/ReceiptCreate');
const ReceiptList = import('../pages/Store/ListReceipt');
const CustomerManagement = import('../pages/CustomerManagement');
const ReceiptDetail = import('../pages/Store/ListReceipt/detail');
const ForgotPassword = import('../pages/ForgotPassword');
const ResetPassword = import('../pages/ResetPassword');
const PersonalSettings = import('../pages/PersonalSettings');
const CustomerDetail = import('../pages/CustomerManagement/Detail');
const CustomerEdit = import('../pages/CustomerManagement/Edit');
const EmployeesManagement = import('../pages/StaffManagement');
const CreateNewEmployees = import('../pages/StaffManagement/CreateNew');
const EmployeeDetail = import('../pages/StaffManagement/Detail');

export const routes: RouteI[] = [
  {
    path: RoutePath.LOGIN,
    Component: lazy(() => LoginPage)
  },
  {
    path: RoutePath.FORGOT_PW,
    Component: lazy(() => ForgotPassword)
  },
  {
    path: RoutePath.RESET_PW,
    Component: lazy(() => ResetPassword)
  },
  {
    path: RoutePath.USER,
    Component: lazy(() => UserPage),
    Layout: <MainLayout />,
    isPrivate: true
  },
  {
    path: RoutePath.RECEIPT_CREATE,
    Component: lazy(() => ReceiptCreate),
    Layout: <AdminStoreLayout />,
    isPrivate: true
  },
  {
    path: RoutePath.RECEIPT_LIST,
    Component: lazy(() => ReceiptList),
    Layout: <AdminStoreLayout />,
    isPrivate: true
  },
  {
    path: RoutePath.HOME,
    Component: lazy(() => ReceiptList),
    Layout: <AdminStoreLayout />,
    isPrivate: true
  },
  {
    path: RoutePath.RECEIPT_DETAIL,
    Component: lazy(() => ReceiptDetail),
    Layout: <AdminStoreLayout />,
    isPrivate: true
  },
  {
    path: RoutePath.CUSTOMER_MANAGE,
    Component: lazy(() => CustomerManagement),
    Layout: <AdminStoreLayout />,
    isPrivate: true
  },
  {
    path: RoutePath.CUSTOMER_DETAIL,
    Component: lazy(() => CustomerDetail),
    Layout: <AdminStoreLayout />,
    isPrivate: true
  },
  {
    path: RoutePath.CUSTOMER_EDIT,
    Component: lazy(() => CustomerEdit),
    Layout: <AdminStoreLayout />,
    isPrivate: true
  },
  {
    path: RoutePath.SETTING,
    Component: lazy(() => PersonalSettings),
    Layout: <AdminStoreLayout />,
    isPrivate: true
  },
  {
    path: RoutePath.STAFF_MANAGE,
    Component: lazy(() => EmployeesManagement),
    Layout: <AdminStoreLayout />,
    isPrivate: true
  },
  {
    path: RoutePath.STAFF_DETAIL,
    Component: lazy(() => EmployeeDetail),
    Layout: <AdminStoreLayout />,
    isPrivate: true
  },
  {
    path: RoutePath.STAFF_EDIT,
    Component: lazy(() => CreateNewEmployees),
    Layout: <AdminStoreLayout />,
    isPrivate: true
  },
  {
    path: RoutePath.STAFF_NEW,
    Component: lazy(() => CreateNewEmployees),
    Layout: <AdminStoreLayout />,
    isPrivate: true
  }
];
