import { BrowserRouter } from 'react-router-dom';
import AppRouter from './router';
import { Provider } from 'react-redux';
import ModalProvider from './contexts/modalContext';
import DrawerProvider from './contexts/drawerContext';
import { store } from './redux/store';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ConfigProvider, ja_JP, App as AppProvider } from 'core-ui';
import theme from './theme';
import { dayjs } from 'core-helpers';
import { useEffect } from 'react';
import { RoutePath } from './enums/routePath';
import ProfileProvider from './contexts/profile';
dayjs.locale('ja');

const queryClient = new QueryClient();

const App = () => {
  return (
    <ConfigProvider theme={theme} locale={ja_JP}>
      <AppProvider>
        <BrowserRouter basename={RoutePath.BASENAME}>
          <QueryClientProvider client={queryClient}>
            <ProfileProvider>
              <Provider store={store}>
                <ModalProvider>
                  <DrawerProvider>
                    <AppRouter />
                  </DrawerProvider>
                </ModalProvider>
              </Provider>
            </ProfileProvider>
          </QueryClientProvider>
        </BrowserRouter>
      </AppProvider>
    </ConfigProvider>
  );
};

export default App;
