import { styled } from 'core-ui';

export const Main = styled.div`
  .layout-admin-dashboard {
    .ant-layout-header {
      background: #1890ff !important;
    }
    .sub-header-admin-dashboard {
      height: 68px !important;
    }

    .ant-menu {
      padding-top: 15px !important;
      .ant-menu-item-selected::after {
        border-inline-start: 3px solid #4759ff !important;
        border-inline-end: none !important;
        left: 0;
      }
      .ant-menu-item-selected {
        color: #1890ff !important;
      }
      .ant-menu-item {
        font-size: 14px;
        font-weight: 600;
        color: #898989;
      }
    }
  }
`;
