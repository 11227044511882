import { Popover } from 'core-ui';
import Cookies from 'js-cookie';
import { RoutePath } from 'src/enums/routePath';
import { ACCESS_TOKEN_KEY } from 'src/constants/auth';
import { UserIcon } from 'src/icons/UserIcon';
import { DropdownIcon } from 'src/icons/DropdownIcon';
import { useNavigate } from 'react-router-dom';

const Header = () => {
  const navigate = useNavigate();
  const handleLogout = () => {
    Cookies.remove(ACCESS_TOKEN_KEY);
    navigate(RoutePath.LOGIN);
  };

  return (
    <div className="h-[100%] flex items-center justify-end px-[20px]">
      <div className="flex items-center">
        <Popover
          placement="bottomRight"
          content={
            <div className="min-w-[100px]">
              <p
                onClick={handleLogout}
                className="cursor-pointer w-full text-[#EA3D3D]"
              >
                ログアウト
              </p>
            </div>
          }
        >
          <div className="flex cursor-pointer items-center">
            <UserIcon />

            <div className="flex">
              <div className="flex flex-col ml-[8px]">
                <p className="leading-[20px] text-white text-[14px] min-w-[60px]">
                  田中 太郎
                </p>
              </div>
            </div>

            <DropdownIcon />
          </div>
        </Popover>
      </div>
    </div>
  );
};

export default Header;
