import { ThemeConfig } from 'core-ui';

export const systemColor = {
  // green: '#618264',
  // greenLight: '#e3efe4',
  // orangeLight: '#FAAD14',
  // black: '#000000',
  // white: '#FFFFFF',
  // yellow: '#FEAB35',
  // successLight: '#51FD00',
  // gray: '#F2F2F2',
  // blue: '#4759FF',
  // blueLight: '#f0f1fb',
  primary: '#1890FF',
  error: '#FF2929'
};

const theme: ThemeConfig = {
  token: {
    colorPrimary: systemColor.primary,
    borderRadius: 2,
    colorError: systemColor.error
    // controlHeightLG: 40, height mặc định của size large đã là 40 rồi
  },
  components: {
    Button: {
      borderRadiusLG: 8,
      borderRadius: 8
    },
    Select: {},
    Input: {
      borderRadius: 2
      // inputFontSizeLG: 14, bỏ phần này đi, không cần thiết làm giống hệt design, nếu chỉ đổi font size large của input thì cần đổi cả select, datepicker,... để các size được đồng bộ
      // controlHeightLG: 40, height mặc định của size large đã là 40 rồi
    },
    Table: {
      headerBg: '#D9D9D9'
    },
    Form: {
      // labelFontSize: 14
    },
    Menu: {
      itemMarginInline: 0,
      itemMarginBlock: 0,
      itemBorderRadius: 0,
      // itemSelectedColor: systemColor.blue,
      // itemSelectedBg: systemColor.blueLight,
      activeBarWidth: 3,
      // itemActiveBg: systemColor.blueLight,
      itemHeight: 40,
      iconSize: 18
      // itemColor: systemColor.black,
      // colorItemBgSelected: systemColor.blueLight
    }
  }
};
export default theme;
