/* eslint-disable no-unused-vars */
export enum RoutePath {
  ALL = '/*',
  HOME = '/',
  BASENAME = '/store',
  LOGIN = '/login',
  FORGOT_PW = '/forgot-password',
  RESET_PW = '/reset-password',
  NOT_FOUND = '/not-found',
  USER = '/user',
  RECEIPT_CREATE = '/qrscan',
  RECEIPT_LIST = '/receipts',
  RECEIPT_DETAIL = '/receipts/:id',
  CUSTOMER_MANAGE = '/customers',
  CUSTOMER_DETAIL = '/customers/:id',
  CUSTOMER_EDIT = '/customers/:id/edit',
  SETTING = '/setting',
  STAFF_MANAGE = '/employees',
  STAFF_NEW = '/employees/new',
  STAFF_EDIT = '/employees/:id/edit',
  STAFF_DETAIL = '/employees/:id'
}
