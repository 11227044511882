export const HistoryIcon = ({ color }: { color: string }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_172_3143)">
      <path
        d="M11.2299 13.3586L11.2296 13.359L11.2299 13.3586C11.1738 13.3185 11.1418 13.2544 11.1418 13.1849V5.81083C11.1418 5.69328 11.238 5.5971 11.3555 5.5971H12.6439C12.7614 5.5971 12.8576 5.69328 12.8576 5.81083V12.4403H12.8572L12.8579 12.4407L16.6775 15.2024L16.6775 15.2024C16.7737 15.2691 16.7951 15.4027 16.7229 15.5016L15.9568 16.5462L15.9573 16.5465L15.9568 16.5462C15.8874 16.6424 15.7538 16.6611 15.6576 16.5916L11.2299 13.3586ZM21.4101 4.90538L19.8092 6.15826C19.5735 5.85572 19.3165 5.56924 19.0434 5.29347C18.1798 4.42722 17.1564 3.73675 16.0298 3.26032C14.8619 2.76475 13.6217 2.51563 12.3412 2.51563C11.0608 2.51563 9.81786 2.76475 8.65529 3.26032C7.52872 3.73675 6.50529 4.42722 5.64172 5.29347C4.76845 6.16406 4.08538 7.1793 3.60856 8.30704C3.113 9.47497 2.86388 10.7179 2.86388 11.9983C2.8611 13.2649 3.11251 14.5192 3.60321 15.6869C4.0827 16.8147 4.76577 17.8299 5.63636 18.7005C6.49993 19.5668 7.52336 20.2572 8.64993 20.7337C9.81786 21.2292 11.0581 21.4784 12.3385 21.4784C13.6056 21.4804 14.8601 21.2271 16.0272 20.7337C17.1537 20.2572 18.1772 19.5668 19.0407 18.7005C19.914 17.8299 20.5971 16.8147 21.0739 15.6869C21.1301 15.553 21.1837 15.4191 21.2346 15.2825C21.2539 15.2301 21.293 15.1873 21.3434 15.1634C21.3938 15.1394 21.4516 15.1361 21.5044 15.1542L23.0232 15.6765C23.1354 15.7166 23.1942 15.8422 23.1541 15.9544C21.5417 20.3658 17.3097 23.5156 12.3412 23.5156C6.02278 23.5156 0.893561 18.4239 0.829278 12.1162C0.764995 5.69596 5.91832 0.483708 12.3359 0.48103C16.0212 0.478352 19.3021 2.21111 21.4101 4.90538ZM18.4514 7.23359L21.4189 4.91571L23.0034 6.94038L22.996 8.43047C22.996 8.56671 22.8651 8.66824 22.7315 8.63618L18.5315 7.61029C18.4924 7.60076 18.4568 7.58039 18.4288 7.55152C18.4008 7.52266 18.3815 7.48647 18.3731 7.44712C18.3647 7.40777 18.3676 7.36686 18.3815 7.32908C18.3953 7.29131 18.4196 7.25821 18.4514 7.23359ZM23.0034 6.94026L21.4193 4.91537L22.6728 3.93627C22.8117 3.82407 23.0174 3.92557 23.0174 4.10458L23.0034 6.94026Z"
        fill={color}
        stroke={color}
        stroke-width="0.00111607"
      />
    </g>
    <defs>
      <clipPath id="clip0_172_3143">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
