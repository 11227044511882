export const DropdownIcon = () => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.91 8.29688L12.5 12.8769L17.09 8.29688L18.5 9.70687L12.5 15.7069L6.5 9.70687L7.91 8.29688Z"
        fill="white"
      />
    </svg>
  );
};
