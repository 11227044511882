import { Button, Layout, Menu, MenuProps } from 'core-ui';
import React, { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import Header from './components/Header';
import Logo from './components/Logo';
import { LeadingIcon } from 'src/icons/LeadingIcon';
import { MemberIcon } from 'src/icons/Member';
import { CustomerIcon } from 'src/icons/CustomerIcon';
import { HistoryIcon } from 'src/icons/HistoryIcon';
import { SettingAccIcon } from 'src/icons/SettingAccIcon';
import _last from 'lodash/last';
import { Main } from './style';

const colorMenuItem = {
  activated: '#1890FF',
  inActive: '#898989'
};

const listPathName = [
  '/employees',
  '/customers',
  '/receipts',
  '/qrscan',
  '/setting'
];
const AdminStoreLayout = () => {
  const [activeMenuItem, setActiveMenuItem] = useState<string>('receipt');
  const handleMenuClick = (e: any) => {
    setActiveMenuItem(e.key);
    navigate(`/${e.key}`);
  };

  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    // scroll on Top content when change page url
    const contentWrapperClass = document.querySelector(
      '.content-wrapper-layout'
    );
    contentWrapperClass?.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    listPathName.forEach((item) => {
      if (pathname && pathname.startsWith(item)) {
        setActiveMenuItem(item.slice(1));
      }
    });
  }, [pathname]);
  const menuItems: MenuProps['items'] = [
    {
      key: 'employees',
      icon: (
        <CustomerIcon
          color={
            activeMenuItem === 'employees'
              ? colorMenuItem.activated
              : colorMenuItem.inActive
          }
        />
      ),
      label: <div className="ml-[10px]">店員管理</div>
    },
    {
      key: 'customers',
      icon: (
        <MemberIcon
          color={
            activeMenuItem === 'customers'
              ? colorMenuItem.activated
              : colorMenuItem.inActive
          }
        />
      ),
      label: <div className="ml-[10px]">顧客管理</div>
    },
    {
      key: 'receipts',
      icon: (
        <HistoryIcon
          color={
            activeMenuItem === 'receipts'
              ? colorMenuItem.activated
              : colorMenuItem.inActive
          }
        />
      ),
      label: <div className="ml-[10px]">レシート履歴</div>
    },
    {
      key: 'qrscan',
      icon: (
        <LeadingIcon
          color={
            activeMenuItem === 'qrscan'
              ? colorMenuItem.activated
              : colorMenuItem.inActive
          }
        />
      ),
      label: <div className="ml-[10px]">レシート発行</div>
    },
    {
      key: 'setting',
      icon: (
        <SettingAccIcon
          color={
            activeMenuItem === 'setting'
              ? colorMenuItem.activated
              : colorMenuItem.inActive
          }
        />
      ),
      label: <div className="ml-[10px]">個人情報設定</div>
    }
  ];

  return (
    <Main>
      <Layout
        className={'!overflow-x-hidden layout-admin-dashboard'}
        headerContent={<Header />}
        logoContent={<Logo />}
        logoClassName="flex justify-center items-center !bg-[#1890FF]"
        siderStyle={{
          overflowX: 'hidden'
        }}
        contentStyle={{
          overflow: 'auto',
          padding: '20px 24px',
          minHeight: '100%',
          marginTop: '88px'
        }}
        contentWrapperClassName="!pt-0 content-wrapper-layout"
        siderContent={
          <Menu
            onClick={handleMenuClick}
            mode="inline"
            selectedKeys={[activeMenuItem]}
            items={menuItems}
          />
        }
      >
        <Outlet />
      </Layout>
    </Main>
  );
};

export default AdminStoreLayout;
