export const SettingAccIcon = ({ color }: { color: string }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.5 20.763H2.95C2.76435 20.763 2.5863 20.6893 2.45502 20.558C2.32375 20.4267 2.25 20.2487 2.25 20.063V18.612C2.25 17.5266 3.02147 16.664 3.9757 16.0146C5.71728 14.8261 8.46748 14.112 11.5 14.112C11.5663 14.112 11.6299 14.0857 11.6768 14.0388C11.7237 13.9919 11.75 13.9283 11.75 13.862C11.75 13.7957 11.7237 13.7321 11.6768 13.6852C11.6299 13.6383 11.5663 13.612 11.5 13.612C8.24761 13.612 5.41338 14.4279 3.69271 15.6011L11.5 20.763ZM11.5 20.763C11.5663 20.763 11.6299 20.7893 11.6768 20.8362C11.7237 20.8831 11.75 20.9467 11.75 21.013C11.75 21.0793 11.7237 21.1429 11.6768 21.1898C11.6299 21.2367 11.5663 21.263 11.5 21.263H2.95C2.63174 21.263 2.32652 21.1366 2.10147 20.9115C1.87643 20.6865 1.75 20.3813 1.75 20.063V18.612C1.75 17.5646 2.38428 16.4934 3.69267 15.6011L11.5 20.763ZM6.5 6.75C6.5 3.99014 8.74014 1.75 11.5 1.75C14.2599 1.75 16.5 3.99014 16.5 6.75C16.5 9.50986 14.2599 11.75 11.5 11.75C8.74014 11.75 6.5 9.50986 6.5 6.75ZM16 6.75C16 4.26586 13.9841 2.25 11.5 2.25C9.01586 2.25 7 4.26586 7 6.75C7 9.23414 9.01586 11.25 11.5 11.25C13.9841 11.25 16 9.23414 16 6.75ZM15.4056 15.3656C15.9594 14.7955 16.713 14.4629 17.5072 14.438C19.194 14.4419 20.563 15.8127 20.563 17.5C20.563 19.1874 19.1938 20.5591 17.5073 20.563C16.713 20.5381 15.9594 20.2055 15.4056 19.6354C14.8501 19.0636 14.5394 18.2977 14.5394 17.5005C14.5394 16.7033 14.8501 15.9374 15.4056 15.3656ZM20.063 17.5C20.063 16.0855 18.9138 14.938 17.5 14.938C16.0862 14.938 14.937 16.0855 14.937 17.5C14.937 18.9141 16.0859 20.063 17.5 20.063C18.9141 20.063 20.063 18.9141 20.063 17.5Z"
      // fill="black"
      stroke={color}
    />
    <path
      d="M19.4895 15.5102C19.4895 15.5101 19.4895 15.5101 19.4896 15.5101L19.8933 15.1067L19.4899 15.5104C19.4899 15.5105 19.4899 15.5105 19.4898 15.5105C19.4896 15.5107 19.4894 15.5108 19.4891 15.5109C19.4892 15.5106 19.4893 15.5104 19.4895 15.5102ZM15.1067 19.8933L15.5101 19.4896C15.5101 19.4895 15.5101 19.4895 15.5102 19.4895C15.5104 19.4893 15.5106 19.4892 15.5109 19.4891C15.5108 19.4894 15.5107 19.4896 15.5105 19.4898C15.5105 19.4899 15.5105 19.4899 15.5104 19.4899L15.1067 19.8933ZM19.8929 19.8929L19.4896 19.4899C19.4895 19.4899 19.4895 19.4899 19.4895 19.4898C19.4893 19.4896 19.4892 19.4894 19.4891 19.4891C19.4894 19.4892 19.4896 19.4893 19.4898 19.4895C19.4899 19.4895 19.4899 19.4895 19.4899 19.4896L19.8929 19.8929ZM15.5102 15.5105C15.5101 15.5105 15.5101 15.5105 15.5101 15.5104L15.1064 15.1064L15.5104 15.5101C15.5105 15.5101 15.5105 15.5101 15.5105 15.5102C15.5106 15.5103 15.5107 15.5105 15.5108 15.5106C15.5109 15.5107 15.5109 15.5108 15.5109 15.5109C15.5106 15.5108 15.5104 15.5107 15.5102 15.5105Z"
      fill="black"
      stroke={color}
      stroke-width="1.5"
    />
  </svg>
);
