export const UserIcon = () => (
  <svg
    width="34"
    height="34"
    viewBox="0 0 34 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.9987 0.332031C7.7987 0.332031 0.332031 7.7987 0.332031 16.9987C0.332031 26.1987 7.7987 33.6654 16.9987 33.6654C26.1987 33.6654 33.6654 26.1987 33.6654 16.9987C33.6654 7.7987 26.1987 0.332031 16.9987 0.332031ZM16.9987 5.33203C19.7654 5.33203 21.9987 7.56536 21.9987 10.332C21.9987 13.0987 19.7654 15.332 16.9987 15.332C14.232 15.332 11.9987 13.0987 11.9987 10.332C11.9987 7.56536 14.232 5.33203 16.9987 5.33203ZM16.9987 28.9987C12.832 28.9987 9.1487 26.8654 6.9987 23.632C7.0487 20.3154 13.6654 18.4987 16.9987 18.4987C20.3154 18.4987 26.9487 20.3154 26.9987 23.632C24.8487 26.8654 21.1654 28.9987 16.9987 28.9987Z"
      fill="white"
    />
  </svg>
);
