import { MailOutlined, MenuOutlined } from 'core-ui';

const Logo = () => {
  return (
    <div className="flex items-center flex-nowrap overflow-x-hidden gap-x-[10px] font-semibold text-[16px] justify-center h-[100%]">
      レシート管理システム
    </div>
  );
};

export default Logo;
