import { ACCESS_TOKEN_KEY } from 'src/constants/auth';
import Cookies from 'js-cookie';
import { useContext, useEffect, useMemo } from 'react';
import { Navigate } from 'react-router-dom';
import { PrivateRouteI } from 'src/models/router';
import { RoutePath } from 'src/enums/routePath';
import { ProfileContext } from 'src/contexts/profile';

export const PrivateRoute = ({ children }: PrivateRouteI) => {
  const { profile, fetchProfile, isProfileLoading } =
    useContext(ProfileContext);

  const isLogined = useMemo(() => {
    return !!Cookies.get(ACCESS_TOKEN_KEY);
  }, []);
  useEffect(() => {
    if (!profile) fetchProfile?.();
  }, [profile]);

  if (!isLogined) return <Navigate to={RoutePath.LOGIN} replace />;
  if (isProfileLoading) return <></>;
  return children;
};
